import React from "react";
import {Button, Icon, Menu, MenuItem} from "@nike/eds";
import {Constants} from "../Constants";
import {CaretDown, CaretUp} from "@nike/nike-design-system-icons";
import {FetchService} from "./FetchService";


class TopicSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            topics: ["loading..."]
        };
    }

    componentDidMount() {
        let url = Constants.APIURL+ "/kafka/topics";
        FetchService(url, "GET", "").then(response => {response.json().then(json => {this.setState({topics: json})})
            });
    }

    setOpen(open) {
        this.setState({"open": open})
    }

    render() {
        return (
            <Menu
                isDark={true}
                onClickOutside={() => {
                    this.setOpen(!this.state.open);
                }}
                isOpen={this.state.open}
                bodySlot={
                    this.state.topics.map(topic => (
                        <MenuItem
                          key={topic}
                          onClick={() => {this.setState({'topic': topic}); this.props.onSelect(topic);}}
                                  beforeSlot={this.state.topic === topic ? <Icon name="Check" /> : <></>}>
                            {topic}
                        </MenuItem>
                    ))
                }
                placement={"bottom-start"}
            >
                <Button
                    beforeSlot={this.state.open ? <CaretUp /> : <CaretDown />}
                    variant="secondary"
                    onClick={() => this.setOpen(!this.state.open)}>
                    {this.state.topic ? this.state.topic : "Select topic"}
                </Button>
            </Menu>
        )

    }

}

export { TopicSelector };
