import React from "react";
import "@nike/eds/dist/index.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createBrowserHistory } from 'history'


import { LoginCallback } from '@okta/okta-react'


import "./App.css";

import {Home} from "./components/Home";

import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { getOktaAuth } from './config/OktaConfig'
import { SecureRoute } from '@okta/okta-react'

function App() {
    const newHistory = createBrowserHistory();

    const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
        newHistory.replace(toRelativeUrl(originalUri || '/', window.location.origin));
        window.location.reload();
        }

    return (
    <Router history={newHistory} >
        <Security oktaAuth={getOktaAuth()} restoreOriginalUri={restoreOriginalUri}>
             <SecureRoute exact path="/" ><Home /></SecureRoute>
              <Route exact path="/implicit/callback">
                 <LoginCallback />
              </Route>
        </Security>
    </Router>

    );
}

export default App;
