import React from "react";
import {Constants} from "../Constants";
import {AppHeader, Button} from "@nike/eds/dist";
import {EventSelector} from "./EventSelector";
import {TopicSelector} from "./TopicSelector";
import {JSONEditor} from "./JSONEditor";
import {NikeApp, Play} from "@nike/nike-design-system-icons";
import { Card } from "@nike/eds";
import {  Snack, Snackbar } from "@nike/eds";
import { Spinner } from "@nike/eds";
import {FetchService} from "./FetchService";

function Header() {

    return (
        <AppHeader
            isDark
            appName="Returns Platform - Ghostbuster Test UI"
            logoSlot={<NikeApp color={"white"} width={"56px"} height={"64px"} />}
        />
    );
}


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.jsonEditor = React.createRef()
        this.state = {
            event: "",
            topic: null,
            textArea:JSON.parse('{"Select the desired payload format or insert your custom payload": "0"}'),
            show: false,
            snackBarText: "",
            loading: false
        };

        // this.selectMessageFlow = this.selectMessageFlow.bind(this);
        this.selectEvent = this.selectEvent.bind(this);
        this.payloadChangedUpdateState = this.payloadChangedUpdateState.bind(this);
        this.selectTopic = this.selectTopic.bind(this);

    }
    updateTextArea(){
        let url = Constants.APIURL  + "/events/" + this.state.event;
        FetchService(url, "GET", "").then(response => {response.json().then(json => {
               this.setState({textArea: json})
               this.jsonEditor.current.update()

         })
        });

      }

    sendPayload(){
        let url = Constants.APIURL + "/kafka/publish";
        this.setState({loading: true});

        FetchService(url, "POST", this.getPayload()).then(r => this.setState({
            "show": true,
            "snackBarText": r.status,
            "loading": false
        }));
    }

    getPayload(){
        let payload = {};
        payload.payload = JSON.stringify(this.state.textArea);
        payload.topicName = this.state.topic;
        return JSON.stringify(payload);
    }

    selectEvent(event) {
        this.setState({
            event
        }, function() {this.updateTextArea()});
    }

    selectTopic(topic) {
        this.setState({
            topic
        })
    }

    payloadChangedUpdateState(e){
        this.setState({
            textArea: e
        });
    }

    render() {
        return (
            <div>
                <Header />
                <div className="eds-grid eds-grid--m-cols-4">
                    <Card className="eds-grid--col-full">
                        <Card className="eds-card eds-grid--rows-4 eds-elevation--4">
                            <TopicSelector onSelect={this.selectTopic}/>
                            <EventSelector flow={this.state.event} onSelect={this.selectEvent}/>
                        </Card>
                        <br/>
                        <Card className="eds-card eds-elevation--4">
                            <JSONEditor payLoadChanged={this.payloadChangedUpdateState} ref={this.jsonEditor} textArea = {this.state.textArea} />
                            <br/>
                            <Button beforeSlot={ this.state.loading ?  <></>: <Play></Play>} size="medium"   disabled={this.state.loading} variant="primary" onClick={() => {this.sendPayload()}}>
                                 { this.state.loading  ? <Spinner size="medium" /> : "Submit payload"}
                            </Button>

                            <Snackbar>
                              {this.state.show && (
                                <Snack id="1" status="success" onDismiss={id => this.setState({"show": false})} >
                                    Successfully published request
                                </Snack>
                              )}
                            </Snackbar>
                        </Card>
                    </Card>
                </div>
            </div>
        );
    }

}

export { Home };
