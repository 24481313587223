import React from "react";
import {Button, Icon, Menu, MenuItem} from "@nike/eds";
import {CaretDown, CaretUp} from "@nike/nike-design-system-icons";
import {Constants} from "../Constants";
import {FetchService} from "./FetchService";

class EventSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            events: ["loading..."]
        };
    }

    componentDidMount() {
        let url = Constants.APIURL+ "/events";
        FetchService(url, "GET", "").then(response => {response.json().then(json => {this.setState({events: json})})
        });
    }

    setOpen(open) {
        this.setState({"open": open})
    }

    render() {
        return (
            <Menu
                isDark={true}
                onClickOutside={() => {
                    this.setOpen(!this.state.open);
                }}
                isOpen={this.state.open}
                bodySlot={
                    this.state.events.map(event => (
                      <MenuItem
                        key={event}
                        beforeSlot={this.state.event === event ? <Icon name="Check" /> : <></>}
                        onClick={() => {this.setState({'event': event}); this.props.onSelect(event)}}>
                          {event}
                      </MenuItem>
                    ))
                }
                placement={"bottom-start"}
            >
                <Button
                    beforeSlot={this.state.open ? <CaretUp /> : <CaretDown />}
                    variant="secondary"
                    onClick={() => this.setOpen(!this.state.open)}>
                    {this.state.event ? this.state.event : "Select event"}
                </Button>
            </Menu>
        )
    }
}

export { EventSelector };
